var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-text-field',{staticClass:"mb-5",attrs:{"append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"mobile-breakpoint":800,"loading":_vm.loading,"footer-props":{
      itemsPerPageText: 'Linhas'
    },"options":{
      itemsPerPage: _vm.itemsPerPage
    },"no-data-text":"Nenhum registro importado"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.entidade",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toUpperCase")(item.entidade))+" ")]}},{key:"item.registros",fn:function(ref){
    var item = ref.item;
return [_c('a',{staticClass:"link",attrs:{"title":"Clique aqui para visualizar os registros importados na entidade"},on:{"click":function($event){_vm.$router.push(
            ("/geracao-bdgd/entidade-bdgd/visualizar/" + (item.entidade.toLowerCase()) + "/" + (_vm.bdgdVersao.id))
          )}}},[_vm._v(_vm._s(_vm._f("parseNumberToIntegerBR")(item.registros)))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }