var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length)?_c('v-container',{staticClass:"pt-0 px-0",attrs:{"fluid":""}},[_c('v-text-field',{staticClass:"mb-5",attrs:{"append-icon":"mdi-magnify","label":"Procurar no histórico de ajustes","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"mobile-breakpoint":800,"loading":_vm.loading,"footer-props":{
      itemsPerPageText: 'Linhas'
    },"options":{
      itemsPerPage: _vm.itemsPerPage
    },"no-data-text":"Nenhum log encontrado"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.entidade",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:{
          alerta: item.acao === 'UPDATE',
          perigo: item.acao === 'DELETE'
        }},[_vm._v(" "+_vm._s(_vm._f("toUpperCase")(item.entidade))+" ("+_vm._s(_vm._f("toUpperCase")(item.acao))+") ")])]}},{key:"item.dado_antes_ajuste",fn:function(ref){
        var item = ref.item;
return [(item.acao === 'DELETE')?_c('span',[_c('v-btn',{staticClass:"px-1",attrs:{"color":"gray","min-width":"0","fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.openDialogViewDeletedRow(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye-outline ")])],1)],1):_c('span',[_vm._v(" "+_vm._s(_vm._f("treatDinamic")(item.dado_antes_ajuste,item.tipo_campo))+" ")])]}},{key:"item.dado_depois_ajuste",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("treatDinamic")(item.dado_depois_ajuste,item.tipo_campo))+" ")]}},{key:"item.data_ajuste",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseToDateTimeBR")(item.data_ajuste))+" ")]}}],null,true)}),_c('dialog-detalhes',{attrs:{"title":(_vm.dialogDeletedRowEntity + " :: linha #" + (_vm.dialogDeletedRowData.gid) + " removida"),"dialog":_vm.dialogDeletedRow},on:{"update:dialog":function($event){_vm.dialogDeletedRow=$event}}},[_c('template',{slot:"body"},[_c('v-simple-table',{staticClass:"ml-3",attrs:{"height":"400px","fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Campo")]),_c('th',{staticClass:"text-left"},[_vm._v("Valor")])])]),_c('tbody',_vm._l((_vm.deletedRow),function(item){return _c('tr',{key:item.campo + item.gid},[_c('td',[_vm._v(_vm._s(item.campo))]),_c('td',[_vm._v(_vm._s(item.valor))])])}),0)]},proxy:true}],null,false,3014069332)})],1)],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }